
  import Cell from '@/components/dataTables/cell/cell'
  import { Component } from 'vue-property-decorator'
  import { mapActions } from 'vuex'
  import GAlert from '@/components/core/alert/GAlert.vue'

@Component({
  components: { GAlert },
  methods: {
    ...mapActions('resources/form', ['republishStock']),
  },
})
  export default class RepublishStock extends Cell {
  stockAlert = {
    open: false,
    title: '¿Estás seguro de republicar este vehículo de todas las plataformas?',
    subtitle: 'Tardará unos minutos en reflejarse la actualización.',
  }

  republishStock!: (id: number) => Promise<void>

  // Methods
  async republish () {
    this.stockAlert.open = false
    await this.republishStock(this.item.id)
  }

  // Getters
  get disabledButton () {
    const { yapo, mercadolibre, chileautos } = this.item

    return Boolean(!yapo?.length && !mercadolibre?.length && !chileautos?.length) || this.item.id !== this.item.carDetail.idStock
  }

  // Watchers
  }
